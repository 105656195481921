import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, Observable, of, Subscription, takeWhile, timer } from 'rxjs';
import { catchError, filter, first, map, mergeMap, tap } from 'rxjs/operators';

import { IApi, IHttpResponse } from '../../interfaces/utils/utils.interface';
import {
  IEvent,
  IEventAsset,
  IEventCategories,
  IEventContentReport,
  IEventContentReportReason,
  IEventDestination,
  IEventPublicationTool,
  IEventRequest,
  IEventTokenCheckResponse,
  IEventTokenSaved,
  IEventUserLastChanges
} from '../../interfaces/events/event.interface';
import { API, API_OPTIONS_LIST, API_OPTIONS_REPORT_CONTENT_REASONS } from '../../const/api.const';
import { EVENTS } from '../../const/events.const';
import { AV_PROD_OPTIONS_EVENT_CATEGORIES, AV_PROD_OPTIONS_LIST } from '../../const/av-producer-options';
import { IAvSettingSelectOption } from '../../interfaces/av-producer/event-av-producer.interface';
import { COMMON } from '../../const/common.const';
import { UserService } from '../user/user.service';
import { IOrganizationInfo } from '../../interfaces/user/user.interface';


@Injectable({
  providedIn: 'root'
})

export class EventsService {

  pCurrentEvent: IEvent | undefined;

  protected pollingInterval: number = 10000;    // 10 seconds
  protected pollingLastChangesTs: number = 10000000000000;
  protected pollingClients: string[]= [];

  protected pollingSubscription: Subscription | undefined;
  protected languageSubscription: Subscription | undefined;
  protected savedTokensSubscription: Subscription | undefined;

  public savedTokens: IEventTokenSaved[] = [];

  public userChanges$: BehaviorSubject<IEventUserLastChanges> = new BehaviorSubject<IEventUserLastChanges>({
    timestamp: 10000000000000
  });

  constructor(private http: HttpClient,
              private userService: UserService,
              private translate: TranslateService) {
    this.onLanguageChanged(this.translate.currentLang);
    this.languageSubscription = this.translate.onLangChange.subscribe((value: LangChangeEvent ) => {
      this.onLanguageChanged(value);
    });

    this.readSavedTokenFromLocalStorage();
    this.checkSavedTokens();
  }

  public get currentEvent(): IEvent | undefined {
    return this.pCurrentEvent;
  }
  public set currentEvent(event: IEvent | undefined) {
    this.pCurrentEvent = event;
  }

  public addPollingClient(client: string): void {
    // Allow multiple clients with same name
    //if (!this.pollingClients.includes(client)){
      this.pollingClients.push(client);
    //}
    if (this.pollingSubscription === undefined) {
      console.log('[EventsService] addPollingClient. START');
      this.pollingLastChangesTs = 10000000000000;
      this.pollingSubscription = timer(0, this.pollingInterval)
        .pipe(
          takeWhile(() => !!this.userService.user)
        )
        .subscribe({
          next: () => this.getUserLastChanges(this.pollingLastChangesTs)
        });
    }
  }

  public removePollingClient(client: string){
    let index: number = this.pollingClients.indexOf(client);
    // Remove only one occurrence
    if (index!== -1){
      this.pollingClients.splice(index, 1);
    }
    // while (index!== -1){
    //   this.pollingClients.splice(index, 1);
    //   index = this.pollingClients.indexOf(client);
    // }

    if (this.pollingClients.length === 0){
      if (this.pollingSubscription !== undefined){
        console.log('[EventsService] removePollingClient. STOP');
        this.pollingSubscription?.unsubscribe();
        this.pollingSubscription = undefined;
      }
    }
  }

  public forcePollingChanges(): void{
    this.getUserLastChanges(this.pollingLastChangesTs);
  }

  protected onLanguageChanged(value: any) {
    //console.log('[EventsService] Language changed');
    this.getEventsCategoriesList()
    .subscribe((categories: IEventCategories[]) => {
      while (AV_PROD_OPTIONS_EVENT_CATEGORIES.length > 0){
        AV_PROD_OPTIONS_EVENT_CATEGORIES.pop();
      }
      for (let i=0; i<categories.length; i++) {
        const OPTION: IAvSettingSelectOption = {
          label: 'avOptions.' + categories[i].name,
          labelTranslate: categories[i].name,       // API returns now translated text
          valueNumber: categories[i].id,
          valueStr: ''
        }
        AV_PROD_OPTIONS_EVENT_CATEGORIES.push(OPTION);
      }
    });

    this.getEventsReportReasonsList(this.translate.currentLang)
    .subscribe((reasons: IEventContentReportReason[]) => {
      while (API_OPTIONS_REPORT_CONTENT_REASONS.length > 0){
        API_OPTIONS_REPORT_CONTENT_REASONS.pop();
      }
      // const OPTION: IAvSettingSelectOption = {
      //   label: 'avOptions.reportContentNone',
      //   labelTranslate: this.translate.instant('avOptions.reportContentNone'),       // API returns now translated text
      //   valueNumber: -1,
      //   valueStr: 'reportContentNone'
      // }
      // API_OPTIONS_REPORT_CONTENT_REASONS.push(OPTION);

      for (let i=0; i<reasons.length; i++) {
        const OPTION: IAvSettingSelectOption = {
          label: 'avOptions.' + reasons[i].reason,
          labelTranslate: reasons[i].reason,       // API returns now translated text
          valueNumber: reasons[i].id,
          valueStr: ''
        }
        API_OPTIONS_REPORT_CONTENT_REASONS.push(OPTION);
      }
      //console.log("[EventsService] Content report reasons: " + JSON.stringify(API_OPTIONS_REPORT_CONTENT_REASONS));
    });

    // Update API option translations
    API_OPTIONS_LIST.forEach(element => {
      element.forEach(options => {
        options.labelTranslate = this.translate.instant(options.label);
      });
    });

    // Update avOptions translations
    AV_PROD_OPTIONS_LIST.forEach(element => {
      element.forEach(options => {
        options.labelTranslate = this.translate.instant(options.label);
      });
    });
  }

  /**
   * Formats an event categories API response into event categories list
   *
   * @param response
   */
  static handleEventCategoriesResponse(response: IHttpResponse): IEventCategories[] {
    let categories: IEventCategories[] = [];
    if(!response.error) {
      if(response.data && response.data?.length > 0) {
        categories = response.data;
      }
    }
    return categories;
  }

  /**
   * Formats an event publication tools API response into event publication list
   *
   * @param response
   */
  static handleEventPublicationToolsResponse(response: IHttpResponse): IEventPublicationTool[] {
    let pubTools: IEventPublicationTool[] = [];
    if(!response.error) {
      if(response.data && response.data?.length > 0) {
        pubTools = response.data;
      }
    }
    return pubTools;
  }

  /**
   * Formats a content reasons API response into reason list
   *
   * @param response
   */
   static handleEventReportReasonsResponse(response: IHttpResponse): IEventContentReportReason[] {
    if(!response.error) {
      let reasons: IEventContentReportReason[] = [];
      if(response.data && response.data?.length > 0) {
        reasons = response.data;
      }
      return reasons;
    } else {
      return [];
    }
  }

  /**
   * Formats an asset list API response into asset list
   *
   * @param response
   */
  static handleEventAssetsResponse(response: IHttpResponse): IEventAsset[] {
    if(!response.error) {
      let assets: IEventAsset[] = [];
      if(response.data && response.data?.length > 0) {
        assets = response.data.map((asset: any) => this.formatEventAssetInput(asset));
      }
      return assets;
    } else {
      return [];
    }
  }

  /**
   * Formats an asset API response
   *
   * @param response
   */
  static handleEventAssetResponse(response: IHttpResponse): IEventAsset | null {
    let asset: IEventAsset | null = null;
    if(!response.error) {
      if(response.data !== undefined) {
        asset = this.formatEventAssetInput(response.data);
      }
    }
    return asset;
  }

  /**
   * Formats a destination API response
   *
   * @param response
   */
  static handleEventDestinationResponse(response: IHttpResponse): IEventDestination | null {
    let dest: IEventDestination | null = null;
    if(!response.error) {
      if(response.data !== undefined) {
        dest = this.formatEventDestinationInput(response.data);
      }
    }
    return dest;
  }

  /**
   * Formats a destination list API response into destination list
   *
   * @param response
   */
  static handleEventDestinationsResponse(response: IHttpResponse): IEventDestination[] {
    if(!response.error) {
      let dests: IEventDestination[] = [];
      if(response.data && response.data?.length > 0) {
        dests = response.data.map((dest: any) => this.formatEventDestinationInput(dest));
      }
      return dests;
    } else {
      return [];
    }
  }

  /**
   * Formats an asset list API response into asset list
   *
   * @param response
   */
  static handleUserLastChangesResponse(response: IHttpResponse): IEventUserLastChanges | null {
    if(!response.error) {
      if(response.data) {
        return response.data;
      }
      return null;
    } else {
      return null;
    }
  }

  /**
   * Sends event data to the API to create an event
   *
   * @param event
   */
  public createEvent(event: IEvent): Observable<IEvent> {
    const EVENT = this.formatEventOutput(event);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.create}`, EVENT)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map(this.handleEventResponse.bind(this)),
        tap((currentEvent: IEvent) => this.currentEvent = currentEvent),
        catchError( () => of(event))
      );
  }

  /**
   * Sends event data to the API to update an event
   *
   * @param event
   */
  public updateEvent(event: IEvent): Observable<IEvent> {
    const EVENT_UPDATE = this.formatEventOutput(event);
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.update}/${event.id}`, EVENT_UPDATE)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val: IHttpResponse) => {
          return this.handleEventResponse(val)
        }),
        catchError( () => of(event))
      );
  }

  /**
   * Requests event data from the API
   *
   * @param id
   */
  public getEvent(id: number): Observable<IEvent | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.get}/${id}`)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val: IHttpResponse) => {
          return this.handleEventResponse(val)
        }),
        catchError( () => of(null))
      );
  }

  /**
   * Request delete event to API
   *
   * @param event
   */
  public deleteEvent(event: IEvent): Observable<boolean> {
    return this.http.delete<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.delete}/${event.id}`, )
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data));
  }

  /**
   * Request archive event to API
   *
   * @param event
   */
  public archiveEvent(eventId: number): Observable<any> {
    const BODY = '';
    return this.http.put<IHttpResponse>( `${API.urls.server}/${API.urls.actions.events.archive}/${eventId}`, BODY )
      .pipe(
        filter((val) => !!val),
        map(this.handleFinishEventResponse.bind(this)),
        catchError( () => of(false)));
  }

  /**
   * Sends request invitation data to API to create and event
   *
   * @param eventRequest
   */
  public sendEventRequestCreation(eventRequest: IEventRequest): Observable<string> {
    const REQUEST = this.formatEventRequestOutput(eventRequest);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.requestCreation}`, REQUEST)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val: IHttpResponse) =>{
          return this.handleEventRequestResponse(val)
        }),
        catchError( () => of('error'))
      );
  }

  /**
   * Checks against API in a user are able to create events
   *
   * @param userId
   */
  public checkCreateEventPermission(userId: number): Observable<boolean> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.creationPermission}/${userId}`)
      .pipe(
        filter((val) => !!val),
        map((response: IHttpResponse) => response.data),
        catchError( (err) => of(err))
      );
  }

  /**
   * Checks against API in a user has events running
   *
   * @param userId
   */
  public checkUserEventConcurrence(userId: number): Observable<boolean> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.concurrencePermission}/${userId}`)
      .pipe(
        filter((val) => !!val),
        map((response: IHttpResponse) => response.data),
        catchError( (err) => of(err))
      );
  }

  /**
   * Calls every 10 seconds to check event status against API
   *
   * @param eventId
   * @param token
   * @param timeInterval
   */
  public checkEventStatus(eventId: number, token: string, timeInterval: number): Observable<string> {
    return interval(timeInterval)
      .pipe(
        mergeMap(() => this.eventStatus(eventId, token)),
        map((resp: IHttpResponse) => resp.data)
      );
  }

  /**
   * Checks against API event status
   *
   * @param eventId
   * @param token
   */
  public eventStatus(eventId: number, token: string): Observable<any> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.status}/${eventId}?token=${token}`)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        catchError( (err) => of(err))
      );
  }

  /**
   * Requests to the API the end of an event
   *
   * @param eventId
   * @param owner
   * @param producerToken
   */
  public finishEvent(eventId: number, owner: boolean, producerToken?: string): Observable<any> {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const BODY = (!owner) ? { token: producerToken } : '';
    return this.http.put<IHttpResponse>( `${API.urls.server}/${API.urls.actions.events.finish}/${eventId}`, BODY )
      .pipe(
        filter((val) => !!val),
        map(this.handleFinishEventResponse.bind(this)),
        catchError( () => of(false)));
  }

  /**
   * Requests to the API the events create by a user
   * 
   * @param orgId Organization identifier
   */
  public getUserEvents(orgId: number): Observable<any> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.getUserEvents}?organization_id=${orgId}`, )
      .pipe(
        filter((val) => !!val),
        map(this.handleEventsResponse.bind(this)),
        catchError( () => of(false))
      );
  }

  /**
   * Requests to the API the events create by a user
   *
   * @param orgId Organization identifier
   */
  public getActiveUserEvents(orgId: number): Observable<IEvent[]> {
    return this.getUserEvents(orgId)
      .pipe(
        map((events: IEvent[]) => events?.filter(
            (event => (event.status === EVENTS.status.running)||
                      (event.status === EVENTS.status.creating)||
                      (event.status?.includes(EVENTS.status.installing) === true)
            )
        ))
      );
  }

  /**
   * API events request filtering by its status
   *
   * @param filterStatus
   * @param orgId Organization identifier
   */
  public getEventsByStatus(filterStatus: string, orgId: number): Observable<any> {
    const HEADERS = new HttpHeaders({'event-status': `${filterStatus}`, source: 'web'});
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.get}?lang=${this.translate.currentLang}&organization_id=${orgId}`, {headers: HEADERS})
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map(this.handleEventsResponse.bind(this))
      );
  }

  /**
   * get event info
   *
   * @param section
   * @param token
   */
  public getEventInfo(section: string, token: string): Observable<IEvent | null> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.getByToken}`, {section, token})
      .pipe(
        map((response: IHttpResponse) => {
          return this.handleEventResponse(response)
        }),
        catchError( () => of(null))
        );
  }

  /**
   * Checks event token and returns event info or null
   *
   * @param token
   * @return
  */
  public checkToken(token: string | undefined): Observable<any> {
    return this.http.get(`${API.urls.server}/${API.urls.actions.events.checkToken}?token=${token}`,)
      .pipe(
        first(),
        map((val: any) => EventsService.handleEventCheckResponse(val, this)),
        catchError( () => of(false))
      );
  }

  /**
   * Saves event token
   *
   * @param token
   * @param tokenType
   * @param name
   * @return
  */
  public saveToken(token: string, tokenType: string, name: string) {
    const FOUND: IEventTokenSaved | undefined = this.savedTokens.find(element => element.token === token);
    if (FOUND === undefined){
      let item: IEventTokenSaved = {
        name: name,
        token: token,
        tokenType: tokenType,
        added: Date.now()
      }
      this.savedTokens.push(item);
      // Store this list in Local Storage
      this.savedTokensSubscription?.unsubscribe();
      this.savedTokensSubscription = timer(1000).subscribe(() => this.storeSavedTokenInLocalStorage());
    }
  }

  /**
   * Stores saved tokens in local storage
   *
   * @return
  */
  public storeSavedTokenInLocalStorage() {
    localStorage.setItem(COMMON.storageKeys.eventTokenList, JSON.stringify(this.savedTokens));
  }

  /**
   * Reads saved tokens from local storage
   *
   * @return
  */
  public readSavedTokenFromLocalStorage() {
    let list: string = localStorage.getItem(COMMON.storageKeys.eventTokenList) ?? '';
    if (list !== ''){
      this.savedTokens = JSON.parse(list);
    }
  }

  /**
   * Removes event token
   *
   * @param token
   * @return
  */
  public removeSavedToken(token: string) {
    for (let i:number = this.savedTokens.length - 1; i >= 0; i--){
      if (this.savedTokens[i].token === token){
        this.savedTokens.splice(i, 1);
      }
    }
    // Store this list in Local Storage
    this.savedTokensSubscription?.unsubscribe();
    this.savedTokensSubscription = timer(1000).subscribe(() => this.storeSavedTokenInLocalStorage());
  }

  /**
   * Checks saved event tokens and removes not valid ones
   *
   * @return
  */
  public checkSavedTokens() {
    console.log('[EventsService] checkSavedTokens');
    const NOW: number = Date.now();
    for (let i:number = this.savedTokens.length - 1; i >= 0; i--){
      if ((this.savedTokens.length > 3)&&
          (this.savedTokens[i].tokenType === 'viewer')&&
          (NOW - this.savedTokens[i].added > 60 * 60 * 24)){  // Keep for 1 day
        this.removeSavedToken(this.savedTokens[i].token);
      }
      else {
        let token: string = this.savedTokens[i].token;
        let item: IEventTokenSaved = this.savedTokens[i];
        this.getEventInfo(item.tokenType, token)
          .pipe()
          .subscribe((event: IEvent | null) => {
            if (event !== null) {
              if ((item.tokenType !== 'viewer')&&
                  ((event.status === 'archived')||
                  (event.status === 'finished'))){
                this.removeSavedToken(token);
              }
              else{
                item.name = event.name;
              }
            }
            else {
              this.removeSavedToken(token);
            }
          });
      }
    }
  }

  /**
   * Returns the list of categories of an event
   */
  public getEventsCategoriesList(): Observable<IEventCategories[]> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.categories}?lang=${this.translate.currentLang}`, )
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val) => EventsService.handleEventCategoriesResponse(val)),
        catchError( () => of([]))
      );
  }

  /**
   * Returns the list of publication tools of an event
   */
  public getEventsPublicationToolList(): Observable<IEventPublicationTool[]> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.events.publicationTools}`, )
      .pipe(
        filter((val) => !!val),
        map((val: IHttpResponse) => EventsService.handleEventPublicationToolsResponse(val)),
        catchError( () => of([]))
      );
  }

  /**
   * Returns the list of report content reasons
   */
   public getEventsReportReasonsList(language: string): Observable<IEventContentReportReason[]> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.contentReport.getReasons}?lang=${language}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        map((val: IHttpResponse) => EventsService.handleEventReportReasonsResponse(val)),
        catchError( () => of([]))
      );
  }

  /**
   * Returns the list of assets owned by current user
   */
  public getUserAssetList(): Observable<IEventAsset[] | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.assets.get}?organization_id=${this.userService.orgId}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        //map((val: any) => { return val; }),
        map((val: IHttpResponse) => EventsService.handleEventAssetsResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Returns a specific asset owned by current user
   */
  public getUserAsset(id: number): Observable<IEventAsset | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.assets.get}/${id}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        //map((val: any) => { return val; }),
        map((val: IHttpResponse) => EventsService.handleEventAssetResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Returns the list of assets associated to a specific running event
   */
  public getEventAssetList(eventCode: string): Observable<IEventAsset[] | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.assets.get}?event_code=${eventCode}`,)
      .pipe(
        filter((val) => !!val),
        first(),
        map((val: IHttpResponse) => EventsService.handleEventAssetsResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Request the update of a user asset
   *
   * @param id
   * @param name
   * @param preload
   */
  public updateUserAsset(id: number,
                        name: string | undefined,
                        preload: boolean | undefined): Observable<any> {
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.assets.set}/${id}`, {name, preload})
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
      );
  }


  /**
   * Request delete user asset to API
   *
   * @param id
   */
  public deleteUserAsset(id: number): Observable<boolean> {
    return this.http.delete<IHttpResponse>(`${API.urls.server}/${API.urls.actions.assets.delete}/${id}`, )
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) =>  {
          console.log('[EventsService] deleteUserAsset ' + JSON.stringify(resp));
          return resp.status_code === 200;
        }),
        catchError( () => of(false)));
  }

  /**
   * Returns the event list of destinations
   *
   * @param eventCode
   */
  public getEventDestinationList(eventCode: string): Observable<IEventDestination[] | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.get}?event_code=${eventCode}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        //map((val: any) => { return val; }),
        map((val: IHttpResponse) => EventsService.handleEventDestinationsResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Returns the list of destinations
   */
  public getUserDestinationList(): Observable<IEventDestination[] | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.get}?check=1&organization_id=${this.userService.orgId}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        //map((val: any) => { return val; }),
        map((val: IHttpResponse) => EventsService.handleEventDestinationsResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Returns the information of a specific destination
   *
   * @param id
  */
  public getUserDestinationInfo(id: number): Observable<IEventDestination | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.get}/${id}`, )
      .pipe(
        filter((val) => !!val),
        first(),
        //map((val: any) => { return val; }),
        map((val: IHttpResponse) => EventsService.handleEventDestinationResponse(val)),
        catchError( () => of(null))
      );
  }

  /**
   * Request delete user destination
   *
   * @param id
   */
  public deleteUserDestination(id: number): Observable<boolean> {
    return this.http.delete<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.delete}/${id}`, )
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) =>  {
          console.log('[EventsService] deleteUserDestination ' + JSON.stringify(resp));
          return resp?.status_code === 200;
        }),
        catchError( () => of(false)));
  }

  /**
   * Request the creation of a new destination
   *
   * @param platform
   * @param name
   * @param active
   * @param settings
   */
  public createUserDestination(platform: string,
                              name: string | undefined,
                              active: boolean | undefined,
                              settings: any): Observable<any> {
    let organization_id: number = this.userService.orgId;
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.create}`, {platform, name, active, settings, organization_id})
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
        );
  }

  /**
   * Request the update of a user destination
   *
   * @param id
   * @param name
   * @param active
   * @param settings
   */
  public updateUserDestination(id: number,
                              name: string | undefined,
                              active: boolean | undefined,
                              settings: any): Observable<any> {
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.set}/${id}`, {name, active, settings})
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
        );
  }

  /**
   * Request the reconnection of an existing destination
   *
   * @param id
   */
  public reconnectUserDestination(id: number): Observable<any> {
    //return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.reconnect}`, {id})
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.destinations.reconnect}?id=${id}`,)
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
        );
  }

  /**
   * Sends video content report message
   * @param contentReport
   */
  public sendContentReport(contentReport: IEventContentReport): Observable<any> {
    const REPORT = this.formatEventContentReport(contentReport);
    return this.http.post(`${API.urls.server}/${API.urls.actions.contentReport.report}`, REPORT)
      .pipe(
        filter((val) => !!val),
        first(),
        map((val: any) => { return val; })
      );
  }

  /**
   * Creates payment stripe checkout session
   * @param key
   * @param orgId
   * @param currency
   */
  public createStripeCheckoutSession(key: string, orgId: number, currency: string | undefined): Observable<any> {
    let organization_id: number = orgId;
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.stripe.createSession}`, {key, organization_id, currency})
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
        );
  }

  /**
   * Returns stripe customer portal url
   *
  */
  public getStripeCustomerPortal(orgId: number): Observable<any> {
    let organization_id: number = orgId;
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.stripe.getCustomerPortal}`, {organization_id})
      .pipe(
        map((val: any) => { return val; }),
        catchError( () => of(null))
        );
  }

  static formatEventAssetInput(asset: IApi<IEventAsset>): IEventAsset {
    return {
      id: asset.id,
      name: asset.name,
      type: asset.type,
      status: asset.status,
      preload: asset.preload,
      size: asset.size,
      userId: asset.user_id,
      organizationId: asset.organization_id,
      config: asset.config,
    };
  }

  static formatEventDestinationInput(dest: IApi<IEventDestination>): IEventDestination {
    return {
      id: dest.id,
      platform: dest.platform,
      name: dest.name,
      active: dest.active,
      settings: dest.settings,
      status: dest.status,
      organizationId: dest.organization_id,
      avatarUrl: dest.avatar_url,
      customUrl: dest.custom_url
    };
  }

  public formatEventOutput(event: IEvent): IApi<IEvent> {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      id: event.id,
      name: event.name,
      user_id: event.userId,
      user_name: event.userName,
      description: event.description,
      category_id: event.categoryId,
      category: event.category,
      status: event.status ?? '-',
      started: event.started ?? 0,
      finished:  event.finished ?? 0,
      ended:  event.ended ?? 0,
      organization_id: event.organizationId,
      private: event.private ?? false,
      protected: event.protected ?? false,
      protected_password: event.protectedPassword ?? '',
      scheduled_finish: event.scheduledFinish ?? 0,
      viewer_token: event.viewerToken ?? '-',
      publisher_token: event.publisherToken ?? '-',
      producer_token: event.producerToken ?? '-',
      host: event.host ?? '-',
      dynamic_poster: event.dynamicPoster,
      static_poster: event.staticPoster,
      current_viewers: event.currentViewers,
      total_viewers: event.totalViewers,
      total_size: event.totalSize,
      expiration_time: event.expirationTime,
      timezone: event.timezone,
      auto_start_mode: event.autoStartMode,
      config_mode: event.configMode,
      config_custom: event.configCustom,
      scheduled_start: event.scheduledStart,
      scheduled_event_start: event.scheduledEventStart,
      max_resolution: event.maxResolution,
    };
  }

  public formatEventInput(event: IApi<IEvent>): IEvent {
    return {
      id: event.id,
      name: event.name,
      userId: event.user_id,
      userName: event.user_name,
      description: event.description,
      categoryId: event.category_id,
      category: event.category,
      status: event.status,
      started: event.started,
      finished: event.finished,
      ended: event.ended,
      organizationId: event.organization_id,
      private: !!event.private,
      protected: !!event.protected,
      protectedPassword: event.protected_password ?? '',
      scheduledFinish: event.scheduled_finish,
      viewerToken: event.viewer_token,
      publisherToken: event.publisher_token,
      producerToken: event.producer_token,
      host: event.host,
      dynamicPoster: event.dynamic_poster,
      staticPoster: event.static_poster,
      currentViewers: event.current_viewers,
      totalViewers: event.total_viewers,
      totalSize: event.total_size,
      expirationTime: event.expiration_time,
      timezone: event.timezone,
      autoStartMode: event.auto_start_mode,
      configMode: event.config_mode,
      configCustom: event.config_custom,
      scheduledStart: event.scheduled_start,
      scheduledEventStart: event.scheduled_event_start,
      maxResolution: event.max_resolution,
    };
  }

  public formatEventRequestOutput(eventRequest: IEventRequest): IApi<IEventRequest> {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      user_id: eventRequest.userId,
      categories: eventRequest.categories,
      publication_tools: eventRequest.publicationTools,
      monthly_frequency: eventRequest.monthlyFrequency,
      average_duration: eventRequest.averageDuration,
      average_audience: eventRequest.averageAudience,
      comments: eventRequest.comments
    };
  }

  public formatEventRequestInput(eventRequest: IApi<IEventRequest>): IEventRequest {
    return {
      userId: eventRequest.user_id,
      categories: eventRequest.categories,
      publicationTools: eventRequest.publication_tools,
      monthlyFrequency: eventRequest.monthly_frequency,
      averageDuration: eventRequest.average_duration,
      averageAudience: eventRequest.average_audience,
      comments: eventRequest.comments
    };
  }

  public formatEventCheckInput(resp: IApi<IEventTokenCheckResponse>): IEventTokenCheckResponse {
    return {
      eventId: resp.event_id,
      tokenType: resp.token_type
    }
  }

  public formatEventContentReport(report: IEventContentReport): IApi<IEventContentReport> {
    return {
      event_id: report.eventId,
      reason_id: report.reasonId,
      comments: report.comments
    }
  }

   /**
   * Formats an event API response into event interface
   *
   * @param response
   */
  private handleEventResponse(response: IHttpResponse): IEvent {
    if(!response.error) {
      return this.formatEventInput(response.data);
    } else {
      throw new Error('Error');
    }
  }

  /**
   * Formats a list of events API response into a list of events
   *
   * @param response
   */
  private handleEventsResponse(response: IHttpResponse): IEvent[] | null {
    if(!response.error) {
      let events: IEvent[] = [];

      if(response.data && response.data?.length > 0) {
        events = response.data.map((event: any) => this.formatEventInput(event));
      }
      return events ?? null;
    } else {
      return null;
    }
  }

  /**
   * Formats an event API response into event interface when finishing an event
   *
   * @param response
   */
  private handleFinishEventResponse(response: IHttpResponse): IEvent | null {
    if(!response.error) {
      if(response.data.status === EVENTS.status.running) {
        response.data.status = EVENTS.status.archived;
      }
      return this.formatEventInput(response.data);
    } else {
      return null;
    }
  }

  /**
   * Formats an event API response into event model
   *
   * @param response
   * @param self
   */
   static handleEventCheckResponse(response: IHttpResponse, self: any): IEventTokenCheckResponse | null {
    let ret: IEventTokenCheckResponse | null = null;
    if(!response.error) {
      if (response.data)
      {
        ret = self.formatEventCheckInput(response.data);
      }
    }
    return ret;
  }

  /**
   * Formats an event API response into event request interface
   *
   * @param response
   */
  private handleEventRequestResponse(response: IHttpResponse): string {
    if(!response.error) {
      return response.data;
    } else {
      throw new Error('error');
    }
  }

  /**
   * get last changes
   *
   * @param ts Last timestamp
   */
  private getUserLastChanges(ts: number) {
    let orgIdParam: string = '';
    if ((this.userService.anonymousUser === false )&&
        (this.userService.orgId !== 0)){
      orgIdParam = '&organization_id=' + this.userService.orgId;
    }
    this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.changes}?last=${ts}${orgIdParam}`,)
      .pipe(
        first(),
        map((val: any) => EventsService.handleUserLastChangesResponse(val)),
        catchError( () => of(null))
      ).subscribe((data) => {
          if (data !== null){
            this.pollingLastChangesTs = data.timestamp;
            if ((data.account !== undefined)||
                (data.events !== undefined)||
                (data.eventList !== undefined)||
                (data.storage !== undefined)||
                (data.destinations !== undefined)||
                (data.organization !== undefined)||
                (data.organizations !== undefined)){
                  this.userChanges$.next(data);
            }
          }
          console.log('[EventsService] getUserLastChanges Out ' + JSON.stringify(data))
        }
      );
  }

}
